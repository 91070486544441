import {
  CheckBoxContainer,
  CheckBoxInput,
  Label,
} from "../StyledComponents/common";
import { inputTypes } from "../constants/common";
import React from "react";

export const CheckBox = ({ label, isChecked = false, onClickCllBack }) => {
  return (
    <CheckBoxContainer onClick={onClickCllBack}>
      <CheckBoxInput
        type={inputTypes.checkbox}
        checked={isChecked}
        onChange={() => undefined}
      />
      <Label>{label}</Label>
    </CheckBoxContainer>
  );
};
