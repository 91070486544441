import {http} from "../constants/common";

const parseHttpGetRequest = httpGetRequest => {
    // prefix includes: protocol, sub domain, domain name, port and path
    const splittedHttpGetRequest = httpGetRequest.split(http.PREFIX_AND_QUERY_STRING_SEPARATOR);
    return {
        prefix: splittedHttpGetRequest[0],
        queryString: splittedHttpGetRequest[1]
    };
};

const isStringsEquals = (stringA, stringB, isCaseSensitive) => isCaseSensitive ? stringA === stringB : stringA.toLowerCase() === stringB.toLowerCase();


const getQueryStringParamDiffs = (param, secondRequestQueryStringArray, isCaseSensitive) => {
    const splittedParam  = param.split(http.QUERY_STRING_ASSIGNMENT);
    const paramName = splittedParam[0];
    const paramValue = splittedParam[1];
    const otherParamIndex = secondRequestQueryStringArray.findIndex( param => isStringsEquals(param.split(http.QUERY_STRING_ASSIGNMENT)[0], paramName, isCaseSensitive));
    const isParamNameExists = otherParamIndex > -1;
    if (!isParamNameExists){
        return {paramsExists: false, paramName, paramValue}
    }
    const isParamsValueEqual = isStringsEquals(secondRequestQueryStringArray[otherParamIndex].split(http.QUERY_STRING_ASSIGNMENT)[1], paramValue, isCaseSensitive);
    if (!isParamsValueEqual){
        const otherParamValue = secondRequestQueryStringArray.find( param => isStringsEquals(param.split(http.QUERY_STRING_ASSIGNMENT)[0], paramName, isCaseSensitive)).split(http.QUERY_STRING_ASSIGNMENT)[1];
        return {paramsExists: true, paramName, paramValue, otherParamValue}
    }
    return {paramEqual: true};
};

const getQueryStringDiffs = (firstRequestQueryString, secondRequestQueryString, isCaseSensitive) => {
    const firstRequestQueryStringArray =  firstRequestQueryString.split(http.QUERY_STRING_SEPARATOR);
    const secondRequestQueryStringArray =  secondRequestQueryString.split(http.QUERY_STRING_SEPARATOR);
    const diffs = firstRequestQueryStringArray.map( param => getQueryStringParamDiffs(param, secondRequestQueryStringArray, isCaseSensitive)).filter( diff => !diff.paramEqual);
    return diffs;
};

export const compareRequests = (firstHttpGetRequest, secondHttpGetRequest, isCaseSensitive) => {
    const {prefix: firstRequestPrefix, queryString: firstRequestQueryString} = parseHttpGetRequest(firstHttpGetRequest);
    const {prefix: secondRequestPrefix, queryString: secondRequestQueryString} = parseHttpGetRequest(secondHttpGetRequest);
    const isPrefixEqual = isStringsEquals(firstRequestPrefix, secondRequestPrefix, isCaseSensitive);
    const firstToSecondCompare = getQueryStringDiffs(firstRequestQueryString, secondRequestQueryString, isCaseSensitive);
    const secondToFirstCompare = getQueryStringDiffs(secondRequestQueryString, firstRequestQueryString, isCaseSensitive);
    return {firstToSecondCompare, secondToFirstCompare, isPrefixEqual};
};
