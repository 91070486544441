import React from "react";
import { Center, DiffsTableContainer, FlexBox, Header, TableCell, TableCellHeader } from "../StyledComponents/common";
import { userText } from "../constants/common";

export const DiffsTable = ({ compareResults, isDecodeURIComponent }) => {
  const { firstToSecondCompare, secondToFirstCompare } = compareResults;
  const firstToSecondCompareTableRows = firstToSecondCompare.map(({ paramName, paramValue, otherParamValue }, index) =>
    createDiffsTableRow(isDecodeURIComponent)(paramName, paramValue, otherParamValue && paramName, otherParamValue, index)
  );
  const secondToFirstCompareTableRows = secondToFirstCompare
    .filter((v) => !v.paramsExists)
    .map(({ paramName, paramValue }, index) => createDiffsTableRow(isDecodeURIComponent)(undefined, undefined, paramName, paramValue, index));
  const diffsTableRef = React.useRef(null);
  const executeScroll = () => diffsTableRef.current.scrollIntoView();
  React.useEffect(executeScroll, []);
  return (
    <DiffsTableContainer ref={diffsTableRef}>
      <Center>
        <Header>Diffs Table</Header>
      </Center>
      <DiffsTableHeaders />
      {firstToSecondCompareTableRows}
      {secondToFirstCompareTableRows}
    </DiffsTableContainer>
  );
};

const DiffsTableHeaders = () => {
  return (
    <FlexBox>
      <TableCellHeader>{userText.compareTableResults.header.LEFT_REQUEST_PARAMS}</TableCellHeader>
      <TableCellHeader>{userText.compareTableResults.header.LEFT_REQUEST_VALUES}</TableCellHeader>
      <TableCellHeader>{userText.compareTableResults.header.RIGHT_REQUEST_PARAMS}</TableCellHeader>
      <TableCellHeader>{userText.compareTableResults.header.RIGHT_REQUEST_VALUES}</TableCellHeader>
    </FlexBox>
  );
};

const createDiffsTableRow = (isDecodeURIComponent) => (i1, i2, i3, i4, key) => {
  return (
    <FlexBox key={key}>
      <TableCell>{i1}</TableCell>
      <TableCell>{isDecodeURIComponent ? i2 && decodeURIComponent(i2) : i2}</TableCell>
      <TableCell>{i3}</TableCell>
      <TableCell>{isDecodeURIComponent ? i4 && decodeURIComponent(i4) : i4}</TableCell>
    </FlexBox>
  );
};
