export const http = {
    QUERY_STRING_SEPARATOR: '&',
    QUERY_STRING_ASSIGNMENT: '=',
    PREFIX_AND_QUERY_STRING_SEPARATOR: '?',
};

export const inputTypes = {
    checkbox: 'checkbox',
};

export const htmlElements = {
    div: 'div',
    body: 'body'
};

export const userText = {
    TEXT_AREA_PLACE_HOLDER: 'Paste HTTP GET request . . ',
    BOTH_FIELDS_ARE_MANDATORY: 'Both input fields are mandatories',
    MISSING_HTTP_PREFIX_AND_QUERY_STRING_SEPARATOR: `Missing http prefix and query string separator, i.e: '${http.PREFIX_AND_QUERY_STRING_SEPARATOR}'`,
    CASE_SENSITIVE_LABEL: 'Case Sensitive',
    DECODE_URI_COMPONENT_LABEL: 'Decode URI component',
    COMPARE_BUTTON: 'Compare',
    CLEAR_ALL_BUTTON: 'Clear All',
    PAGE_HEADER: 'HTTP GET Requests Query Params Compare - compare url online',
    EQUAL_REQUESTS: 'Both Requests Are Equals !!',
    HOW_TO_USE: 'HTTP GET Requests Query Params Compare! is an online diff tool that can find the difference between two HTTP GET requests, just Paste and compare.' +
        'This tool takes GET parameter from first URL and tries to find same name parameter in second URL and then compares their values.',
    NOT_EQUAL_PREFIX: 'Prefixes [i.e protocol, sub domain, domain name, port and path] are not equal',
    compareTableResults: {
        header: {
            LEFT_REQUEST_PARAMS: 'Left Request Params',
            LEFT_REQUEST_VALUES: 'Left Request Values',
            RIGHT_REQUEST_PARAMS: 'Right Request Params',
            RIGHT_REQUEST_VALUES: 'Right Request Values'
        }
    },
    EXECUTE_REQUEST: 'Execute Request',
    pageHeader: {
        ABOUT_BUTTON: 'About',
    },
    about: {
        BACK: 'Compare Requests',
        ABOUT_CONTENT: `As a Full-Stack Engineer, I frequently need to compare pairs of HTTP GET requests params in order to understand why one works and the other doesn't or ` +
            `why the responses are different. Since the query params order have no meaning, I can't use text compare tools. ` +
        `Therefore, I created this tool in order to save some time.`,
        QUICK_START: 'Quick Start',
        STEP_ONE: '1. Paste two HTTP GET requests',
        STEP_TWO: '2. Choose your desired comparison behavior: case sensitive or not',
        STEP_THREE: '3. Click the compare button and see the results table',
    }
};
