import {htmlElements} from "../constants/common";
import {mainColors} from "../StyledComponents/colors";
import {FONT_FAMILY} from "../StyledComponents/utilityFunctions";

export const showUserMessage = (text, timeout = 4000) => {
    const container = document.createElement(htmlElements.div);
    const {style: containerStyle} = container;
    containerStyle.height = '400px';
    containerStyle.width = '100%';
    containerStyle.position = 'absolute';
    containerStyle.top = '200px';
    containerStyle.display = 'flex';
    containerStyle.justifyContent = 'center';
    containerStyle.alignItems = 'center';

    const messageContainer = document.createElement(htmlElements.div);
    const {style: messageContainerStyle} = messageContainer;
    messageContainerStyle.width = '700px';
    messageContainerStyle.padding = '30px';
    messageContainerStyle.border = `2px solid ${mainColors.OUT_LINE}`;
    messageContainerStyle.backgroundColor = mainColors.BACKGROUND;
    messageContainerStyle.color = mainColors.OUT_LINE;
    messageContainerStyle.fontFamily = FONT_FAMILY;
    messageContainerStyle.fontSize = '25px';
    messageContainer.innerText = text;

    container.appendChild(messageContainer);

    const removeUserMessage = () => {
        container.remove();
        document.body.onclick = null;
    };

    document.body.onclick = removeUserMessage;

    document.getElementsByTagName(htmlElements.body)[0].appendChild(container);
    setTimeout( removeUserMessage, timeout);
};
