export const resource = {
    images: {
        STEP_ONE: {
            IMAGE: require('../Resources/stepOne.png'),
            ALT: 'url string compare first step, paste requests in order to compare urls url arg diff url param compar check if two urls are equal online'
        },
        STEP_THREE: {
            IMAGE: require('../Resources/stepThree.png'),
            ALT: 'compare url requesr third step in our url compare tool сщьзфку гкды трк прометей алексей яременко find differences between url',
        }
    }
};









