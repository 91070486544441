import React from "react";
import { showUserMessage } from "../utilities/showUserMessage";
import { DiffsTable } from "./DiffsTable";
import { userText } from "../constants/common";

const Equal = () => {
  showUserMessage(userText.EQUAL_REQUESTS);
  return null;
};

export const Results = ({ compareResults, isDecodeURIComponent }) => {
  const { isPrefixEqual } = compareResults;
  const isEqual =
    compareResults.firstToSecondCompare.length === 0 &&
    compareResults.secondToFirstCompare.length === 0;
  return (
    <>
      {!isPrefixEqual && showUserMessage(userText.NOT_EQUAL_PREFIX)}
      {isEqual && isPrefixEqual && <Equal />}
      {!isEqual && <DiffsTable compareResults={compareResults} isDecodeURIComponent={isDecodeURIComponent} />}
    </>
  );
};
