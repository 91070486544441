import React from "react";
import {
    FlexBox,
    Center,
    Header,
    Button,
    TextArea,
    HttpGetRequestsCompareAppContainer,
    TextAreaContainer, AboutContainer,
} from "./StyledComponents/common";
import { userText } from "./constants/common";
import { About } from "./components/About";
import { CheckBox } from "./components/CheckBox";
import { compareRequests } from "./BL/queryParamsCompare";
import { validateHandler } from "./utilities/validateHandler";
import {Results} from "./components/Results";
import {PageHeader} from "./components/Header";

export const App = () => {
  const stateInitValues = {
    httpGetRequestsValues: { first: String(), second: String() },
    compareResults: undefined,
    isCaseSensitive: true,
    isDecodeURIComponent: true,
  };
  const [isCaseSensitive, setIsCaseSensitive] = React.useState(
    stateInitValues.isCaseSensitive
  );
  const [isDecodeURIComponent, setIsDecodeURIComponent] = React.useState(
      stateInitValues.isDecodeURIComponent
  );
  const [httpGetRequestsValues, setHttpGetRequestsValues] = React.useState(
    stateInitValues.httpGetRequestsValues
  );
  const [compareResults, setCompareResults] = React.useState(
    stateInitValues.compareResults
  );
  const [isAboutPage, setIsAboutPage] = React.useState(false);
  const setHttpGetRequestsValuesHoc = (newHttpGetRequestsValues) => {
    setCompareResults(stateInitValues.compareResults);
    setHttpGetRequestsValues(newHttpGetRequestsValues);
  };
  const updateFirstHttpGetRequestsValues = (event) => {
    setHttpGetRequestsValuesHoc({
      first: event.target.value.trim(),
      second: httpGetRequestsValues.second,
    });
  };
  const updateSecondHttpGetRequestsValues = (event) => {
    setHttpGetRequestsValuesHoc({
      first: httpGetRequestsValues.first,
      second: event.target.value.trim(),
    });
  };
  const onCompareButtonClick = (isCaseSensitive) => {
    const { first, second } = httpGetRequestsValues;
    validateHandler(first, second) &&
      setCompareResults(compareRequests(first, second, isCaseSensitive));
  };
  const onIsCaseSensitiveClicked = () => {
    const newIsCaseSensitive = !isCaseSensitive;
    setIsCaseSensitive(newIsCaseSensitive);
    compareResults && onCompareButtonClick(newIsCaseSensitive);
  };
  const onIsDecodeURIComponentClicked = () => {
    const newIsDecodeURIComponent = !isDecodeURIComponent;
    setIsDecodeURIComponent(newIsDecodeURIComponent);
    compareResults && onCompareButtonClick(isCaseSensitive);
  };
  const clearAll = () => {
    setHttpGetRequestsValues(stateInitValues.httpGetRequestsValues);
    setCompareResults(stateInitValues.compareResults);
  };
  const onAboutClicked = () => setIsAboutPage(!isAboutPage);
  return (
    <>
      {isAboutPage ? (
        <About backToMainPage={onAboutClicked} />
      ) : (
        <HttpGetRequestsCompareAppContainer>
          <PageHeader onAboutClicked={onAboutClicked} />
          <Center>
            <Header>{userText.PAGE_HEADER}</Header>
          </Center>
          <Center>
            <AboutContainer>{userText.HOW_TO_USE}</AboutContainer>
          </Center>
          <FlexBox>
            <TextAreaContainer>
              <TextArea
                placeholder={userText.TEXT_AREA_PLACE_HOLDER}
                value={httpGetRequestsValues.first}
                onChange={updateFirstHttpGetRequestsValues}
              />
            </TextAreaContainer>
            <TextAreaContainer>
              <TextArea
                placeholder={userText.TEXT_AREA_PLACE_HOLDER}
                value={httpGetRequestsValues.second}
                onChange={updateSecondHttpGetRequestsValues}
              />
            </TextAreaContainer>
          </FlexBox>
          <FlexBox>
            <CheckBox
                isChecked={isCaseSensitive}
                label={userText.CASE_SENSITIVE_LABEL}
                onClickCllBack={onIsCaseSensitiveClicked}
            />
            <CheckBox
                isChecked={isDecodeURIComponent}
                label={userText.DECODE_URI_COMPONENT_LABEL}
                onClickCllBack={onIsDecodeURIComponentClicked}
            />
          </FlexBox>
          <Center>
            <Button onClick={() => onCompareButtonClick(isCaseSensitive)}>
              {userText.COMPARE_BUTTON}
            </Button>
            <Button onClick={clearAll}>{userText.CLEAR_ALL_BUTTON}</Button>
          </Center>
          {compareResults && <Results compareResults={compareResults} isDecodeURIComponent={isDecodeURIComponent}/>}
        </HttpGetRequestsCompareAppContainer>
      )}
    </>
  );
};
