import styled from "styled-components";
import {getFontStyle} from "./utilityFunctions";
import {mainColors} from "./colors";

export const TextArea = styled.textarea`
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    color: ${mainColors.OUT_LINE};
    font-size: 25px;
    ${getFontStyle()};
    :focus {
        outline: none;
        border: none;
           color: ${mainColors.GREEN}
    }
     resize: none;
`;

export const TextAreaContainer = styled.div`
    width: 100%;
    border: 1.5px solid ${mainColors.OUT_LINE};
    border-radius: 6px;
    height: 300px;
    margin: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const FlexBox = styled.div`
    display: flex;
`;

export const Button = styled.button`
    cursor: pointer;
    background-color: transparent;
    color: ${mainColors.OUT_LINE};
    font-size: 20px;
    margin: 5px;
    border-radius: 6px;
    ${getFontStyle()};
    &:hover {
        color: ${mainColors.HOVER_BUTTON_TEXT};
    }
`;

export const Center = styled.div`
    display: flex;
    justify-content: center;
`;

export const CheckBoxInput = styled.input`
    cursor: pointer;
`;

export const P = styled.p`
    color: ${mainColors.OUT_LINE};
    ${getFontStyle()};
     font-size: 20px;
`;

export const AboutContainer = styled(P)`
    padding: 0px 20px;
`;

export const Label = styled.label`
    cursor: pointer;
    color: ${mainColors.OUT_LINE};
    ${getFontStyle()}
`;

export const CheckBoxContainer = styled.div`
    cursor: pointer;
    margin: 0 6px;
    width: fit-content;
`;

export const HttpGetRequestsCompareAppContainer = styled.div`
    padding: 40px 100px;
`;

export const TableCell = styled.label`
    width: 25%;
    height: 45px;
    color: ${mainColors.OUT_LINE};
    border: 1px solid ${mainColors.OUT_LINE};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
      overflow: auto;
    ${getFontStyle()}
`;

export const TableCellHeader = styled(TableCell)`
    font-size: 25px;
    height: 50px;
`;

export const DiffsTableContainer = styled.div`
    margin: 30px 0;
`;

export const Header = styled.h1`
    width: fit-content;
    border-bottom: 1px solid ${mainColors.OUT_LINE};
    color: ${mainColors.OUT_LINE};
    ${getFontStyle()}
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
`;
