import React from "react";
import {
  Button,
  Center,
  FlexBox,
  Header,
  HttpGetRequestsCompareAppContainer,
  Image,
  P,
} from "../StyledComponents/common";
import { userText } from "../constants/common";
import { resource } from "../constants/resource";
import { CheckBox } from "./CheckBox";

export const About = ({ backToMainPage }) => {
  return (
    <HttpGetRequestsCompareAppContainer>
      <Button onClick={backToMainPage}>{userText.about.BACK}</Button>
      <P>{userText.about.ABOUT_CONTENT}</P>
      <Center>
        <Header>{userText.about.QUICK_START}</Header>
      </Center>
      <Header>{userText.about.STEP_ONE}</Header>
      <Image
        src={resource.images.STEP_ONE.IMAGE}
        alt={resource.images.STEP_ONE.ALT}
      />
      <Header>{userText.about.STEP_TWO}</Header>
      <FlexBox>
        <CheckBox isChecked={true} label={userText.CASE_SENSITIVE_LABEL} />
        <CheckBox isChecked={false} label={userText.CASE_SENSITIVE_LABEL} />
      </FlexBox>
      <Header>{userText.about.STEP_THREE}</Header>
      <Image
        src={resource.images.STEP_THREE.IMAGE}
        alt={resource.images.STEP_THREE.ALT}
      />
    </HttpGetRequestsCompareAppContainer>
  );
};
