import {showUserMessage} from "./showUserMessage";
import {http, userText} from "../constants/common";

export const validateHandler = (first, second) => {
    if (!first || !second) {
        showUserMessage(userText.BOTH_FIELDS_ARE_MANDATORY);
        return false;
    }
    if (!first.includes(http.PREFIX_AND_QUERY_STRING_SEPARATOR) || !second.includes(http.PREFIX_AND_QUERY_STRING_SEPARATOR)){
        showUserMessage(userText.MISSING_HTTP_PREFIX_AND_QUERY_STRING_SEPARATOR);
        return false;
    }
    return true;
};
